/* eslint-disable */

// import external dependencies
import 'jquery';

// Import everything from autoload
import './autoload/_bootstrap.js'

/****
  *************
  *************
    Passive event listeners - for google page speed
    for some reason this doesn't work when placed in common.js
  *************
  *************
*****/
jQuery.event.special.touchstart = {
  setup: function (_, ns, handle) {
    this.addEventListener('touchstart', handle, {
      passive: !ns.includes('noPreventDefault'),
    });
  },
};
jQuery.event.special.touchmove = {
  setup: function (_, ns, handle) {
    this.addEventListener('touchmove', handle, {
      passive: !ns.includes('noPreventDefault'),
    });
  },
};
/****
  *************
  *************
    Lazy Loading Images
    for some reason this doesn't work when placed in common.js
  *************
  *************
*****/
document.addEventListener("DOMContentLoaded", function () {
  var lazyloadImages;

  if ("IntersectionObserver" in window) {
    lazyloadImages = document.querySelectorAll(".lazy");
    var imageObserver = new IntersectionObserver(function (entries, observer) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          // main target
          var mainTarget = entry.target;
          // img tag
          var image = entry.target.src;
          // css background images
          var bgImage = entry.target.getAttribute('data-bgimage');
          if (bgImage) {
            mainTarget.style.backgroundImage = bgImage;
          }
          var bgSize = entry.target.getAttribute('data-bgsize');
          if (bgSize) {
            mainTarget.style.backgroundSize = bgSize;
          }
          var bgPosition = entry.target.getAttribute('data-bgposition');
          if (bgPosition) {
            mainTarget.style.backgroundPosition = bgPosition;
          }
          var bgRepeat = entry.target.getAttribute('data-bgrepeat');
          if (bgRepeat) {
            mainTarget.style.backgroundRepeat = bgRepeat;
          }

          entry.target.classList.remove("lazy");
          imageObserver.unobserve(entry.target);
        }
      });
    });

    lazyloadImages.forEach(function (mainTarget) {
      imageObserver.observe(mainTarget);
    });
  } else {
    var lazyloadThrottleTimeout;
    lazyloadImages = document.querySelectorAll(".lazy");

    function lazyload() {
      if (lazyloadThrottleTimeout) {
        clearTimeout(lazyloadThrottleTimeout);
      }

      lazyloadThrottleTimeout = setTimeout(function () {
        var scrollTop = window.pageYOffset;
        lazyloadImages.forEach(function (img) {
          if (img.offsetTop < (window.innerHeight + scrollTop)) {
            img.src = img.dataset.src;
            img.classList.remove('lazy');
          }
        });
        if (lazyloadImages.length == 0) {
          document.removeEventListener("scroll", lazyload);
          window.removeEventListener("resize", lazyload);
          window.removeEventListener("orientationChange", lazyload);
        }
      }, 20);
    }

    document.addEventListener("scroll", lazyload);
    window.addEventListener("resize", lazyload);
    window.addEventListener("orientationChange", lazyload);
  }
});

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import singleLocation from './routes/location';
import simpleParallax from 'simple-parallax-js';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Single location
  singleLocation,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
